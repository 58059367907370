<template>
  <div class="speakingRoleplay">
    <template v-if="finish">
      <finish 
        class="animate__animated animate__fadeIn"
        :duration="duration"
        :audio="audio"
        :recordData="recordData"
        :dialoguesData="dialoguesData"
        @redo="redoHandle"></finish>
    </template>

    <template v-else>
      <template v-if="ready">
        <div class="ready">
          <div class="countdown animate__animated animate__bounceIn animate__repeat-3">{{countdownSecond}}</div>
          <template v-if="role === 'male'">
            <img class="roleImg" src="@/assets/images/courses/dialogue/roleA.png" alt="role">
            <div class="roleText">{{$t('course.role_play_a')}}</div>
          </template>
          <template v-else>
            <img class="roleImg" src="@/assets/images/courses/dialogue/roleB.png" alt="role">
            <div class="roleText">{{$t('course.role_play_b')}}</div>
          </template>
        </div>
      </template>

      <template v-else>
        <div class="lessonHeader">
          <div class="close" @click="closeHandle">
            <img src="@/assets/images/clips/lesson/close.png" alt="close">
          </div>
        </div>

        <div class="lessonMain">
          <div class="lessonWrap">
            <template v-for="(dialogue, i) in dialogues">
              <speaking
                :key="i"
                :dialogueData="dialogue"
                :audioIndex="i"
                :audio="audio">
              </speaking>
            </template>
          </div>
        </div>

        <div v-show="recording" class="recordingWrap" @click="endRecord">
          <div class="recordingText">{{$t('main.tap_here_when_you_re_done')}}</div>
          <canvas id="waveform" width="400"></canvas>
        </div>
      </template>
    </template>

  </div>
</template>

<script>
  import Recorder from '@/utils/recorderUtil'
  import speaking from '@/components/courses/speaking/SpeakingDialogue.vue'
  import finish from '@/components/courses/RoleplayFinish.vue'
  import learnTimeMixins from '@/mixins/learnTimeMixins'

  export default {
    mixins: [ learnTimeMixins ],
    data() {
      return {
        dialogues: [],
        dialogueIndex: 0,
        role: 'male',
        ready: true,
        countdownSecond: 3,
        recording: false,
        recorder: null,
        drawRecordId: null,
        angle: 0,
        canvas: null,
        ctx: null,
        recordData: [],
        finish: false
      }
    },
    props: ['dialoguesData', 'audio'],
    components: {
      speaking,
      finish
    },
    watch: {
      ready(val) {
        if (!val) {
          this.$nextTick(() => {
            this.initRecorder()
            this.init()
          })
        }
      },
      dialogueIndex(index) {
        if (index) {
          if (this.dialoguesData.length) {
            if (index < this.dialoguesData.length) {
              this.$nextTick(function() {
                // 让页面始终在最底部
                let scrollArea = document.querySelector('.lessonMain')
                scrollArea.scrollTop = scrollArea.scrollHeight
              })
              this.init()
            } else {
              setTimeout(() => {
                if (this.role === 'male') {
                  this.changeRole()
                } else {
                  this.finish = true
                  this.timingStop()
                }
              }, 800)
            }
          }
        }
      },
      'audio.playState': function(val) {
        if (val === 'end') {
          setTimeout(() => {
            this.dialogueIndex ++
          }, 500)
        }
      }
    },
    mounted() {
      this.readyCountDown()
      this.timingStart()
    },
    methods: {
      init() {
        let currentDialogue = this.dialoguesData[this.dialogueIndex]
        this.dialogues.push(currentDialogue)

        if (currentDialogue.type === this.role) {
          this.recordHandle()
        } else {
          this.audio.play(this.dialogueIndex)
        }
      },
      initRecorder() {
        this.recorder = Recorder
        this.canvas = document.getElementById('waveform')
        this.ctx = this.canvas.getContext('2d')
      },
      changeRole() {
        this.dialogues = []
        this.dialogueIndex = 0
        this.role = 'female'
        this.countdownSecond = 3
        this.ready = true
        this.readyCountDown()
      },
      readyCountDown() {
        let timer = setInterval(() => {
          if (this.countdownSecond === 1) {
            clearInterval(timer)
            this.ready = false
            return
          }
          this.countdownSecond --
        }, 1000)
      },
      closeHandle() {
        this.$router.push('/courses')
      },
      redoHandle() {
        Object.assign(this.$data, this.$options.data())
        this.readyCountDown()
        this.duration = 0
        this.timingStart()
      },
      recordHandle() {
        this.recording = true
        this.recorder.start(this)
        this.drawRecord()
      },
      endRecord() {
        let recordURL = URL.createObjectURL(this.recorder.getRecordBlob())
        this.$set(this.recordData, this.dialogueIndex, recordURL)

        this.recording = false
        this.drawRecordId && cancelAnimationFrame(this.drawRecordId)
        this.drawRecordId = null

        this.dialogueIndex ++
      },
      drawRecord() {
        let canvas = this.canvas
        let ctx = this.ctx

        // 实时获取音频大小数据
        let dataArray = this.recorder.getRecordAnalyseData()
        let bufferLength = dataArray.length

        // 清空画布
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        // 填充背景色
        ctx.fillStyle = '#F3F4F8'
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        // 绘制竖条状图形
        let barWidth = (canvas.width / bufferLength) * 2.5
        let x = 0

        for (let i = 0; i < bufferLength; i++) {
          let barHeight = dataArray[i] * 0.1

          // 添加默认运动效果
          let yOffset = Math.sin(this.angle + i * 0.5) * 10
          barHeight += yOffset

          ctx.fillStyle = 'rgb(0, 78, ' + (barHeight + 100) + ')'
          ctx.fillRect(x, (canvas.height - barHeight) / 2, barWidth, barHeight)

          x += barWidth + 1
        }

        // 更新动画参数
        this.angle += 0.05

        // 用requestAnimationFrame稳定60fps绘制
        this.drawRecordId = requestAnimationFrame(this.drawRecord)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .speakingRoleplay {
    .ready {
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .countdown {
        width: 75px;
        height: 75px;
        line-height: 75px;
        border-radius: 75px;
        background: #FFFFFF;
        text-align: center;
        font-weight: 600;
        font-size: 40px;
        color: #97C854;
      }
      .roleImg {
        margin-top: 60px;
        width: 295px;
      }
      .roleText {
        margin-top: 10px;
        font-weight: 400;
        font-size: 14px;
        color: #585971;
      }
    }
    .lessonHeader {
      margin: 0 auto;
      padding-top: 10px;
      height: 30px;
      width: 90vw;
      max-width: 960px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      .close {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        cursor: pointer;
        img {
          width: 17px;
        }
      }
    }
    .lessonMain {
      position: relative;
      width: 100%;
      height: calc(100vh - 30px);
      overflow-x: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        width: 0;
      }
      .lessonWrap {
        margin: 0 auto;
        padding: 20px 20px 180px;
        max-width: 600px;
        .tips {
          padding: 20px 0;
          text-align: center;
          font-weight: 400;
          font-size: 14px;
          color: #2F2F4D;
        }
      }
    }
    .recordingWrap {
      position: fixed;
      bottom: 0;
      width: 100%;
      background: #F3F4F8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .recordingText {
        font-weight: 400;
        font-size: 18px;
        color: #9090A2;
      }
    }
  }
</style>

<style lang="scss">
  .animate__bounceIn {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: calc(var(--animate-duration));
    animation-duration: calc(var(--animate-duration));
  }
</style>