<template>
  <div class="roleplayFinish">
    <div class="myRecordingWrap">
      <div class="myRecording">
        <img class="myRecordingIcon" src="@/assets/images/courses/dialogue/myRecording.png" alt="recordIcon">
        <div class="myRecordingText">{{$t('course.play_recording')}}</div>
      </div>
      <div class="myRecordingBtn" @click="playAllRecord">
        <img v-if="!myRecordPlaying" class="myRecordingBtnIcon" src="@/assets/images/courses/dialogue/recordPlay.png" alt="recordPlay">
        <img v-else class="myRecordingBtnIcon" src="@/assets/images/courses/dialogue/recordPause.png" alt="recordPause">
      </div>
    </div>

    <div class="dialoguesWrap">
      <template v-for="(dialogue, i) in dialoguesData">
        <div class="dialogue" :key="i">
          <div class="dialogueContent">
            <div class="dialogueSent">{{dialogue.st}}</div>
            <div class="dialogueTrans">{{dialogue.tr}}</div>
          </div>
          <div class="dialogueSound">
            <div class="dialogueAudio" ref="dialogueAudio" @click="playAudio(i)"></div>
            <div class="dialogueRecord" @click="playRecord(i)"></div>
          </div>
        </div>
      </template>
    </div>

    <div class="finishPanel">
      <div class="redoBtn" @click="redoHandle">{{$t('course.redo_lesson')}}</div>
      <div class="doneBtn" @click="doneHandle">{{$t('course.FINISH')}}</div>
    </div>
  </div>
</template>

<script>
  import Audio from '@/utils/audioUtil'
  import { updateHistoryData } from '@/utils/dataUpdateUtil'

  export default {
    data() {
      return {
        recordAudio: null,
        myRecordPlaying: false,
        myRecordIndex: 0
      }
    },
    props: ['audio', 'recordData', 'dialoguesData', 'duration'],
    watch: {
      'recordAudio.playState': function(val) {
        if (val === 'end' && this.myRecordPlaying) {
          if (this.myRecordIndex < this.recordData.length) {
            this.recordAudio.play(this.myRecordIndex ++)
          } else {
            this.myRecordPlaying = false
            this.myRecordIndex = 0
          }
        }
      }
    },
    mounted() {
      this.initRecordAudio()
      this.updateData()
    },
    methods: {
      initRecordAudio() {
        let audios = []
        for (let recordData of this.recordData) {
          audios.push({ audioUrl: recordData })
        }
        this.recordAudio = new Audio(audios)
      },
      playAllRecord() {
        if (this.myRecordPlaying) {
          this.myRecordPlaying = false
          this.recordAudio.stop()
        } else {
          this.myRecordPlaying = true
          this.recordAudio.play(this.myRecordIndex)
          this.myRecordIndex ++
        }
      },
      playAudio(index) {
        this.audio.play(index)
      },
      playRecord(index) {
        this.recordAudio.play(index)
      },
      redoHandle() {
        this.$emit('redo')
      },
      doneHandle() {
        this.$router.push('/courses')
      },
      updateData() {
        updateHistoryData(this.duration, 0)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .roleplayFinish {
    margin: 0 auto;
    padding-top: 50px;
    width: 90vw;
    min-height: 100vh;
    max-width: 600px;
    .myRecordingWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 70px;
      border-radius: 70px;
      padding: 20px;
      background: #FFFFFF;
      border: 1px solid #CFD6DD;
      box-sizing: border-box;
      .myRecording {
        display: flex;
        align-items: center;
        justify-content: center;
        .myRecordingIcon {
          width: 40px;
        }
        .myRecordingText {
          margin-left: 40px;
          font-weight: 500;
          font-size: 18px;
          color: #2F2F4D;
        }
      }
      .myRecordingBtn {
        width: 41px;
        height: 41px;
        cursor: pointer;
        .myRecordingBtnIcon {
          width: 100%;
        }
      }
    }
    .dialoguesWrap {
      padding: 20px;
      .dialogue {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 20px;
        padding: 10px 0;
        border-bottom: 1px solid #C4C9D8;
        .dialogueContent {
          .dialogueSent {
            font-weight: 500;
            font-size: 18px;
            color: #2F2F4D;
          }
          .dialogueTrans {
            margin-top: 8px;
            font-weight: 400;
            font-size: 14px;
            color: #585971;
          }
        }
        .dialogueSound {
          display: flex;
          align-items: center;
          .dialogueAudio {
            width: 24px;
            height: 24px;
            background-image: url('~@/assets/images/courses/audio.png');
            background-repeat: no-repeat;
            background-size: 72px;
            background-position: -48px 0;
            cursor: pointer;
            &.active {
              animation: audio 0.6s infinite steps(3);
              @keyframes audio {
                0% {
                  background-position: 0 0;
                }
                100% {
                  background-position: -72px 0;
                }
              }
            }
          }
          .dialogueRecord {
            margin-left: 26px;
            width: 24px;
            height: 24px;
            background-image: url('~@/assets/images/courses/dialogue/listen.png');
            background-repeat: no-repeat;
            background-size: 24px;
            cursor: pointer;
          }
        }
      }
    }
    .finishPanel {
      padding: 50px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .redoBtn, .doneBtn {
        min-width: 230px;
        height: 42px;
        line-height: 42px;
        border-radius: 42px;
        border: 2px solid #004E94;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s;
      }
      .redoBtn {
        margin-right: 60px;
        background: #FFFFFF;
        color: #004E94;
        &:hover {
          color: #0A4172;
          border: 2px solid #0A4172;
        }
      }
      .doneBtn {
        background: #004E94;
        color: #FFFFFF;
        &:hover {
          background: #0A4172;
          border: 2px solid #0A4172;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .roleplayFinish {
      .finishPanel {
        padding: 20px 0;
        flex-direction: column;
        .redoBtn {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
</style>