<template>
  <div class="dialogueModel speakingDialogue" :class="dialogueData.type">
    <div class="stemWrap">
      <img class="personIcon" :src="require(`@/assets/images/${dialogueData.type}.png`)">
      <div class="sentWrap" @click="playAudioHandle">
        <div class="sent">
          <div class="audio" ref="dialogueAudio"></div>
          <template v-for="(word, i) in words">
            <div v-if="word.ty === '1'" class="punc" :class="{highlight: word.highlight}" :key="i">{{word.wd}}</div>
            <div v-else class="word" :class="{highlight: word.highlight}" :key="i">{{word.wd}}</div>
          </template>
        </div>
        <div class="trans">{{dialogueData['tr']}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import dialogueMixins from '@/mixins/coursesDialogueMixins'

  export default {
    mixins: [ dialogueMixins ]
  }
</script>